import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";

import router from "./router";

// Css styles
import "./assets/styles/styles.scss";
import { i18n } from "@/plugins/i18n";

require("moment/locale/ru");
Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
