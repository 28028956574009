import Vue from "vue";
import VueRouter from "vue-router";
import MainComponent from '@/views/MainComponent.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: MainComponent,
    children: [
      {
        path: "",
        component: () => import("../views/HomeView.vue"),
      }
    ]
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
